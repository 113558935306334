<template>
  <!-- Inicio Formulario -->
  <!-- SM to MD Screen -->
  <div class="container-fluid p-0 pb-2 pt-3 d-block d-sm-block d-md-none d-lg-none d-xl-none" >
    <div class="row justify-content-center">
      <div class="col-12 image-container">
        <img src="@/assets/img/home_4.png" class="img-fluid bg-img" alt="Responsive image" />
        <div class="image-text">
          <p>
          Consigue un préstamo
          </p>
          <p>
          con <b>tu coche</b>
          </p>
          <p>
            como garantia
          </p>
          <div >
            <ul class="tick-list" style="list-style-type: none;">
              <li>
                <el class="tick">✔</el> Sin cambiar titular
              </li>
              <li>
                <el class="tick">✔</el> 100% online
              </li>
              <li>
                <el class="tick">✔</el> En menos de 24h.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4">
        <div class="row justify-content-center align-items-center franja-azul">
          <div class="col-xs-11 col-sm-11">
            <h1 class="title title-1 m-0" >
              {{ keyWord ? keyWord : "¡Consigue hasta 6.000€ hoy!" }}
            </h1>
          </div>
        </div>
      </div>
      <NuevoFormulario />
    </div>
    <br />
  </div>
  <!-- MD to LG Screen -->
  <div class="container-fluid p-0 pb-3 d-none d-sm-none d-md-block d-lg-block d-xl-none" >
    <div class="row justify-content-center">
      <div class="col-md-6 col-lg-6">
        <div class="row justify-content-center pt-5">
          <div class="col-10 top mt-5">
            <!-- <h1 v-if="step == 1 || step >= 5" class="title title-1 pb-3 pt-5 text-left desktop"><strong>La mejor solución para {{keyWord ? keyWord : 'un crédito'}} con tu coche</strong></h1> -->
            <h1 class="title title-1 pb-3 pt-5 text-left" >

              {{ keyWord ? keyWord : "El único préstamo con el coche como garantía" }}
            </h1>
            <p class="title title-2 text-left">Consigue en 1 min tu propuesta de préstamo grátis y sin compromiso</p>
            <div class="review-widget_net float-left" data-uuid="9c8b113f-e480-46ac-87cd-cf0e14cf65fa" data-template="2" data-lang="es" data-theme="light"></div>

          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-4">
        <div >
          <img src="@/assets/img/home_4.png" class="bg-img" alt="Responsive image" />
          <!-- <Formulario/> -->
        </div>
      </div>
      <NuevoFormulario />
    </div>
  </div>
  <!-- XL to XXL Screen-->
  <div class="container-fluid p-0 d-none d-xl-block" >
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="row justify-content-center pt-5">
          <div class="col-xl-10 offset-md-1 p-3 top">
            <!-- <h1 v-if="step == 1 || step >= 5" class="title title-1 pb-3 pt-5 text-left desktop"><strong>La mejor solución para {{keyWord ? keyWord : 'un crédito'}} con tu coche</strong></h1> -->
            <h1 class="title title-1 pb-3 pt-5 text-left" >
              {{ keyWord ? keyWord : "El único préstamo con el coche como garantía" }}
            </h1>
            <p class="title title-2 text-left">Consigue en 1 min tu propuesta de préstamo grátis y sin compromiso</p>
            <div class="review-widget_net float-left" data-uuid="9c8b113f-e480-46ac-87cd-cf0e14cf65fa" data-template="2" data-lang="es" data-theme="light"></div>

          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="bg-img"></div>
        <div class="d-flex justify-content-center">
          <div class="position-lg pr-2" style="z-index: 1 !important">
            <NuevoFormulario class="d-none d-xl-block" />
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
  <!--  -->
  <!-- Fin Formulario -->
  <!-- Inicio Columnas con imágenes SVGs -->
  <div class="container-fluid pt-3 pb-1 bg-orange">
    <div class="row text-center justify-content-between align-items-center pt-0 mt-3 mb-5">
      <div class="col-12">
        <div class="row justify-content-center">
          <div class="card-columns">
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-row align-items-center">
                  <div class="col-3">
                    <svg width="100%" height="61" viewBox="0 0 49 61" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M47.5712 60.9087H2.12191C1.50126 60.9087 0.998047 60.3992 0.998047 59.7708V1.34848C0.998047 0.720071 1.50126 0.210571 2.12191 0.210571H47.5712C48.1918 0.210571 48.6951 0.720071 48.6951 1.34848V59.7708C48.6951 60.3992 48.1918 60.9087 47.5712 60.9087ZM3.24577 58.6329H46.4473V2.48639H3.24577V58.6329ZM36.1631 56.4993C35.9049 56.4993 35.6521 56.4091 35.4492 56.2402C35.1896 56.024 35.0393 55.7017 35.0393 55.3614V54.6619L31.4218 54.215C31.0945 54.1743 30.8012 53.9905 30.6197 53.7114C30.4382 53.4329 30.3868 53.0873 30.4792 52.767L31.884 47.8885C30.2707 46.6023 29.2331 44.6082 29.2331 42.3727C29.2331 38.5035 32.342 35.3558 36.1634 35.3558C39.9846 35.3558 43.0934 38.5035 43.0934 42.3727C43.0934 44.2702 42.3458 45.9938 41.1331 47.2584L42.9664 53.9177C43.0505 54.2232 43.0049 54.5507 42.8406 54.8206C42.6762 55.0903 42.4079 55.2786 42.1008 55.3398L36.3803 56.478C36.3078 56.4925 36.2354 56.4993 36.1631 56.4993ZM37.287 53.6545V53.9783L40.4748 53.3442L39.192 48.6833C38.5991 48.976 37.9594 49.186 37.2873 49.2972L37.287 53.6545ZM33.0073 52.1181L35.0393 52.3696V49.2972C34.6487 49.2323 34.2692 49.1348 33.9033 49.0065L33.0073 52.1181ZM36.1631 37.6316C33.5813 37.6316 31.4806 39.7587 31.4806 42.3727C31.4806 44.9868 33.5811 47.1136 36.1631 47.1136C38.7449 47.1136 40.8454 44.9868 40.8454 42.3727C40.8454 39.7587 38.7449 37.6316 36.1631 37.6316Z"
                        fill="#333333"
                      />
                      <path
                        d="M25.2491 53.8963H9.59115C8.97049 53.8963 8.46729 53.3868 8.46729 52.7584C8.46729 52.13 8.97049 51.6205 9.59115 51.6205H25.2491C25.8697 51.6205 26.3729 52.13 26.3729 52.7584C26.3729 53.3868 25.8697 53.8963 25.2491 53.8963ZM25.2491 46.4968H9.59115C8.97049 46.4968 8.46729 45.9873 8.46729 45.3588C8.46729 44.7304 8.97049 44.2209 9.59115 44.2209H25.2491C25.8697 44.2209 26.3729 44.7304 26.3729 45.3588C26.3729 45.9873 25.8697 46.4968 25.2491 46.4968ZM25.2491 39.0972H9.59115C8.97049 39.0972 8.46729 38.5877 8.46729 37.9593C8.46729 37.3309 8.97049 36.8214 9.59115 36.8214H25.2491C25.8697 36.8214 26.3729 37.3309 26.3729 37.9593C26.3729 38.5877 25.8697 39.0972 25.2491 39.0972ZM40.1001 31.6977H9.59115C8.97049 31.6977 8.46729 31.1882 8.46729 30.5597C8.46729 29.9313 8.97049 29.4218 9.59115 29.4218H40.0998C40.7205 29.4218 41.2237 29.9313 41.2237 30.5597C41.2237 31.1882 40.7207 31.6977 40.1001 31.6977ZM40.1001 24.2978H9.59115C8.97049 24.2978 8.46729 23.7883 8.46729 23.1599C8.46729 22.5315 8.97049 22.022 9.59115 22.022H40.0998C40.7205 22.022 41.2237 22.5315 41.2237 23.1599C41.2237 23.7883 40.7207 24.2978 40.1001 24.2978ZM40.1001 16.8986H9.59115C8.97049 16.8986 8.46729 16.3891 8.46729 15.7606C8.46729 15.1322 8.97049 14.6227 9.59115 14.6227H40.0998C40.7205 14.6227 41.2237 15.1322 41.2237 15.7606C41.2237 16.3891 40.7207 16.8986 40.1001 16.8986ZM40.1001 9.49872H9.59115C8.97049 9.49872 8.46729 8.98922 8.46729 8.36081C8.46729 7.7324 8.97049 7.2229 9.59115 7.2229H40.0998C40.7205 7.2229 41.2237 7.7324 41.2237 8.36081C41.2237 8.98922 40.7207 9.49872 40.1001 9.49872Z"
                        fill="#FFD748"
                      />
                    </svg>
                  </div>
                  <div class="col-9">
                    <p class="card-text pl-1 text-left">No cambiamos la titularidad del coche.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-row align-items-center">
                  <div class="col-3">
                    <svg width="100%" height="69" viewBox="0 0 75 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M68.2644 0.715576H5.97982C2.69837 0.715576 0.0288086 3.35831 0.0288086 6.60648V46.1025C0.0288086 49.3509 2.69837 51.9934 5.97982 51.9934H23.7093V58.22H20.8108C16.2883 58.22 12.609 61.8622 12.609 66.339V66.6442C12.609 67.4533 13.2717 68.1092 14.0889 68.1092H60.1549C60.9723 68.1092 61.6349 67.4531 61.6349 66.6442V66.339C61.6349 61.8622 57.9555 58.22 53.4331 58.22H50.5346V51.9934H68.264C71.5455 51.9934 74.215 49.3509 74.215 46.1025V6.60648C74.2155 3.35831 71.5459 0.715576 68.2644 0.715576ZM53.4332 61.1503C55.9212 61.1503 58.0096 62.8745 58.5432 65.1793H15.701C16.2345 62.8745 18.3231 61.1503 20.8108 61.1503H53.4332ZM26.6693 58.2201V51.9936H47.5749V58.2201H26.6693ZM71.2555 46.1027H71.2554C71.2554 47.7351 69.9138 49.0634 68.2644 49.0634H5.97982C4.33047 49.0634 2.98888 47.7351 2.98888 46.1027V6.60648C2.98888 4.97408 4.33047 3.64575 5.97982 3.64575H68.2646C69.9136 3.64575 71.2555 4.97408 71.2555 6.60648V46.1027Z" fill="#333333" />
                      <path d="M65.7975 8.04041H8.44627C7.62892 8.04041 6.96631 8.69646 6.96631 9.50542V43.2024C6.96631 44.0115 7.62906 44.6674 8.44627 44.6674H65.7975C66.6148 44.6674 67.2774 44.0113 67.2774 43.2024V9.50542C67.2776 8.69632 66.6148 8.04041 65.7975 8.04041ZM64.3175 41.7372H9.92624V10.9706H64.3175V41.7372Z" fill="#333333" />
                      <path d="M44.7201 34.3399C44.8016 34.8878 45.1882 35.3435 45.7193 35.5176C46.2505 35.6917 46.8355 35.5545 47.231 35.1628L49.7161 32.7025L55.0797 38.0119C55.3687 38.2979 55.7474 38.441 56.1263 38.441C56.5052 38.441 56.884 38.298 57.1729 38.0119L61.3589 33.8681C61.9371 33.2958 61.9371 32.3684 61.3589 31.7961L55.9955 26.4868L58.4806 24.0268C58.8763 23.6351 59.0148 23.0561 58.8391 22.5303C58.6632 22.0047 58.203 21.6219 57.6493 21.5413L44.4601 19.6213C43.9968 19.5543 43.529 19.7072 43.1982 20.0348C42.8672 20.3624 42.7123 20.8256 42.7804 21.284L44.7201 34.3399ZM54.3053 24.0161L52.8559 25.4508C52.2778 26.0231 52.2778 26.9505 52.8559 27.5228L58.2193 32.8321L56.1263 34.904L50.7627 29.5946C50.4738 29.3086 50.095 29.1654 49.7161 29.1654C49.3372 29.1654 48.9585 29.3084 48.6696 29.5946L47.2202 31.0295L45.9986 22.8071L54.3053 24.0161Z" fill="#333333" />
                      <path d="M36.7221 21.1321C37.011 21.4181 37.3898 21.5613 37.7687 21.5613C38.1476 21.5613 38.5263 21.4183 38.8153 21.1321C39.3934 20.5598 39.3934 19.6324 38.8153 19.0601L35.4851 15.7636C34.9071 15.1918 33.9701 15.1918 33.3921 15.7636C32.814 16.3359 32.814 17.2633 33.3921 17.8356L36.7221 21.1321Z" fill="#333333" />
                      <path d="M33.3291 26.6892H37.5842C38.4016 26.6892 39.0642 26.0331 39.0642 25.2242C39.0642 24.4152 38.4014 23.7592 37.5842 23.7592H33.3291C32.5117 23.7592 31.8491 24.4152 31.8491 25.2242C31.8491 26.0331 32.5119 26.6892 33.3291 26.6892Z" fill="#333333" />
                      <path d="M36.5378 29.4078L33.1152 32.7958C32.5371 33.3681 32.5371 34.2955 33.1152 34.8678C33.4042 35.1538 33.7829 35.297 34.1618 35.297C34.5407 35.297 34.9195 35.1539 35.2084 34.8678L38.631 31.4798C39.2091 30.9075 39.2091 29.9801 38.631 29.4078C38.053 28.8358 37.1158 28.8358 36.5378 29.4078Z" fill="#333333" />
                      <path d="M52.9605 25.4653L54.4273 24.0141L46.0205 22.7911L47.2568 31.1082L48.7237 29.6568C49.0161 29.3674 49.3994 29.2227 49.7829 29.2227C50.1664 29.2227 50.5497 29.3675 50.8421 29.6568L56.2703 35.0274L58.3886 32.9316L52.9605 27.5612C52.3754 26.9823 52.3754 26.0442 52.9605 25.4653Z" fill="#FFD748" />
                      <path d="M26.6875 32.2637C23.5405 32.2637 20.9131 30.0968 20.1563 27.1851H23.3017C24.2378 27.1851 24.9945 26.4267 24.9945 25.4923C24.9945 24.5579 24.2378 23.7995 23.3017 23.7995H20.1563C20.9129 20.8878 23.5403 18.7208 26.6875 18.7208C27.6237 18.7208 28.3803 17.9624 28.3803 17.028C28.3803 16.0936 27.6237 15.3352 26.6875 15.3352C21.6648 15.3352 17.4936 19.0037 16.6826 23.7997H16.5302C15.594 23.7997 14.8374 24.5581 14.8374 25.4925C14.8374 26.4269 15.594 27.1853 16.5302 27.1853H16.6826C17.4918 31.9812 21.663 35.6497 26.6875 35.6497C27.6237 35.6497 28.3803 34.8913 28.3803 33.9569C28.3805 33.0221 27.6237 32.2637 26.6875 32.2637Z" fill="#FFD748" />
                    </svg>
                  </div>
                  <div class="col-9">
                    <p class="card-text pl-3 text-left">100% online</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-row align-items-center">
                  <div class="col-3">
                    <svg width="100%" height="57" viewBox="0 0 59 57" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M21.3872 8.71246C20.0059 4.7517 16.2404 1.90869 11.8088 1.90869C6.20621 1.90869 1.66455 6.45035 1.66455 12.0528C1.66455 16.198 4.15216 19.7601 7.71476 21.334C9.99048 15.2361 15.0704 10.5053 21.3872 8.71246Z" fill="#FFD748" />
                      <path d="M8.62171 23.4852L7.06784 22.7987C2.8127 20.9193 0.0632324 16.7011 0.0632324 12.0528C0.0632324 5.57624 5.33234 0.307129 11.8089 0.307129C16.7993 0.307129 21.2564 3.47302 22.8997 8.18509L23.4591 9.78941L21.8247 10.2532C16.0363 11.896 11.3228 16.2477 9.21559 21.894L8.62171 23.4852ZM11.8089 3.51033C7.09859 3.51033 3.26644 7.34249 3.26644 12.0528C3.26644 14.8803 4.66576 17.4891 6.93475 19.0672C9.39337 13.8628 13.8119 9.78349 19.1902 7.7525C17.6809 5.16335 14.8841 3.51033 11.8089 3.51033Z" fill="#1F2C47" />
                      <path d="M46.9837 1.90857C42.5519 1.90857 38.7863 4.75157 37.4053 8.71233C43.7218 10.5052 48.802 15.236 51.0775 21.3338C54.6401 19.7599 57.1277 16.1977 57.1277 12.0525C57.1277 6.45023 52.5861 1.90857 46.9837 1.90857Z" fill="#FFD748" />
                      <path d="M50.1694 23.4854L49.5756 21.8939C47.4683 16.2478 42.7548 11.896 36.9665 10.2531L35.332 9.78929L35.8915 8.18497C37.5347 3.47289 41.9918 0.307007 46.9822 0.307007C53.4588 0.307007 58.7279 5.57612 58.7279 12.0527C58.7279 16.7008 55.9784 20.919 51.7233 22.7989L50.1694 23.4854ZM39.6009 7.75285C44.9793 9.78369 49.3978 13.863 51.8564 19.0675C54.1254 17.4893 55.5247 14.8805 55.5247 12.0532C55.5247 7.34284 51.6926 3.51069 46.9822 3.51069C43.907 3.51053 41.1103 5.16354 39.6009 7.75285Z" fill="#1F2C47" />
                      <path d="M29.3944 56.0424C15.5265 56.0424 4.24414 44.7601 4.24414 30.8921C4.24414 17.0242 15.5265 5.7417 29.3944 5.7417C43.2624 5.7417 54.5447 17.024 54.5447 30.892C54.5447 44.7599 43.2622 56.0424 29.3944 56.0424ZM29.3944 8.9449C17.2927 8.9449 7.44734 18.7904 7.44734 30.892C7.44734 42.9935 17.2929 52.8391 29.3944 52.8391C41.496 52.8391 51.3415 42.9935 51.3415 30.892C51.3415 18.7904 41.496 8.9449 29.3944 8.9449Z" fill="#1F2C47" />
                      <path d="M29.3965 50.6076C18.5251 50.6076 9.68042 41.7631 9.68042 30.8915C9.68042 20.02 18.5249 11.1757 29.3965 11.1757V14.3789C20.2912 14.3789 12.8836 21.7864 12.8836 30.8917C12.8836 39.997 20.2912 47.4045 29.3965 47.4045C38.5017 47.4045 45.9093 39.997 45.9093 30.8917H49.1125C49.1123 41.7631 40.2678 50.6076 29.3965 50.6076Z" fill="#1F2C47" />
                      <path d="M29.712 34.6516L21.4338 30.5125L22.8663 27.6474L29.0796 30.7539L37.3204 22.5132L39.5856 24.7782L29.712 34.6516Z" fill="#1F2C47" />
                    </svg>
                  </div>
                  <div class="col-9">
                    <p class="card-text pl-1 text-left">Préstamo de hasta 6.000€ en 24h.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <div class="d-flex flex-row align-items-center">
                  <div class="col-3">
                    <img src="@/assets/img/image12.svg" alt="asnef" />
                  </div>
                  <div class="col-9">
                    <p class="card-text pl-1 text-left">Aceptamos personas en ASNEF</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";

import { mapState } from "vuex";
import NuevoFormulario from "../NuevoFormulario/NuevoFormulario.vue";

export default {
  name: "Section1",
  data() {
    return {
      publicPath: process.env.BASE_URL    };
  },
  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    data_matricula: (state) => state.formulario.data_matricula,
    title: (state) => state.section1.title,
    formulario: (state) => state.formulario,
  }),
  props: {
    keyWord: String,
  },
  methods: {
    sumarDinero: function (suma) {
      let calculo = parseFloat(this.formulario.dinero) + suma;
      if (calculo >= 600 && calculo <= 6000) {
        this.formulario.dinero = calculo;
      }
    },
    getYear(fecha) {
      let date = new Date(fecha);
      return date.getFullYear();
    },
  },
  components: {
    NuevoFormulario,
  },
  mounted() {
    store.commit("setTitleSection1", "<strong>Consigue un préstamo rápido con tu coche como garantía</strong>");
    const menu = document.querySelector('#navigation_menu');
    menu.style.display = 'block';
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables.scss";

.title {
  width: 100%;
  font-family: "Montserrat";
  font-display: swap;
  font-style: normal;
  // line-height: auto;
  text-align: center;
  color: $blue;
}
.image-container {
  position: relative;
  text-align: center;
  color: $white;
  background-color: $white;
}
.tick-list {
  font-weight: 600;
  font-size: 13px;
  line-height: 23px;
  color: black;
  margin-top: 2.5vh;
  margin-left: -5vh;
}
.image-text {
  position: absolute;
  top: 21vw;
  left: 6.5vw;
  text-align: justify;
  color: $blue;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 2.5vh;
  line-height: 2vw;
}
.image-text b {
  font-weight: bolder;
  font-family: "Montserrat-bold";
}
.tick {
  color: $blue;
  font-size: 16px;
}
.title-1 {
  font-family: "Montserrat-bold";
  font-style: normal;
  font-weight: 600;
  // font-size: 65px;
}
.title-2 {
  font-family: "Montserrat";
  font-size: 24px;
  line-height: 29px;
}

.card-text {
  font-family: "Montserrat-bold";
  color: $blue;
}

.franja-azul {
  background-color: $blue;
  height: 70px;
}

// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-only(md) {
  #navigation_menu {
    display: block !important;
  }
}

@include media-breakpoint-only(sm) {
  #navigation_menu {
    display: block !important;
  }
}
@include media-breakpoint-only(xs) {
  #navigation_menu {
    display: block !important;
  }
}

@include media-breakpoint-only(xs) {
  .title-1 {
    font-size: 20px !important;
    color: white;
  }
  .bg-img {
    width: 75%;
    margin: 60px 0px -18px 133px;
  }
  .tick {
    font-size: 12px;
  }
  .card {
    min-height: 103px;
    height: 103px;
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $blue;
    font-weight: bold;
  }
  .card-columns {
    column-count: 1;
    max-width: 100%;
    width: 85%;
  }
  .image-text {
    font-size: 20px !important;
    line-height: 10px;
  }
}
@include media-breakpoint-only(sm) {
  .title-1 {
    font-size: 22px !important;
    color: white;
  }
  .image-text {
    font-size: 22px;
    line-height: 10px;
  }
  .bg-img {
    width: 75%;
    margin: 50px 0px -27px 150px;
  }
  .card {
    min-width: 310px !important;
    width: 310px !important;
    min-height: 103px;
    height: 103px;
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $blue;
    font-weight: bold;
  }
  .card-columns {
    column-count: 2;
    max-width: 100%;
    width: 85%;
  }
}

@include media-breakpoint-only(md) {
  .title-1 {
    font-size: 33px !important;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .bg-img {
    width: 125%;
    margin: 75px 0px -30px -94px;
  }
  .card {
    min-width: 310px !important;
    width: 310px !important;
    min-height: 103px;
    height: 103px;
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $blue;
    font-weight: bold;
  }
  .card-columns {
    column-count: 2;
    max-width: 85%;
  }
}

@include media-breakpoint-only(lg) {
  .top {
    margin-top: 30px !important;
  }
  .title .title-1 {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: bold;
    font-size: 65px;
    line-height: 79px;
    color: #004680;
    text-shadow: 0px 4px 4px;
  }

  .bg-img {
    width: 175%;
    margin: 55px 0px -55px -163px;
  }

  .card-columns {
    column-count: 2;
    max-width: 85%;
  }
  .card {
    min-width: 310px !important;
    width: 310px !important;
    min-height: 103px;
    height: 103px;
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $blue;
    font-weight: bold;
  }
  .card-box {
    margin-top: 18px;
  }
  .title-1 {
    font-size: 42px !important;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title-2 {
    font-size: 24px;
  }
}

@include media-breakpoint-only(xl) {
  .bg-img {
    // margin-top: 60px;
    // background-image: linear-gradient(to right, white, transparent, rgb(6, 0, 37)), url("../../assets/img/home_2.webp");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 600px;
    min-width: auto;
    margin: 0;
  }
  .position-lg {
    margin-top: -21.5vh;
  }
  .top {
    margin-top: 20px !important;
  }
  .card-columns {
    column-count: 2;
    padding-right: 5%;
    padding-left: 5%;
  }
  .card {
    min-width: 333px !important;
    width: 333px !important;
    height: 103px;
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $blue;
    font-weight: bold;
  }

  .title-1 {
    font-size: 40px !important;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title-2 {
    font-size: 24px;
  }
}

@include media-breakpoint-between(xl, xxl) {
  .bg-img {
    // margin-top: 60px;
    background-image: linear-gradient(to right, white, transparent 20%), url("../../assets/img/home_2.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }

  .top {
    margin-top: 15% !important;
  }
  .card-columns {
    column-count: 4;
  }
  .card {
    min-width: 100% !important;
    width: 100% !important;
    height: 103px;
    border: 1px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    color: $blue;
    font-weight: bold;
  }
  .title-1 {
    font-size: 52px !important;
    // text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
  .title-2 {
    font-size: 28px;
  }
}
</style>
