<template>
  <div class="card-box">
    <div class="row justify-content-center aling-content-center d-lg-none d-md-none">
      <div class="col-md-5 col-sm-12 d-none d-sm-none">
        <div class="row text-center justify-content-center">
          <p class="box-title-h2">¿Cuánto dinero necesitas?</p>
          <router-link to="/formulario/step1" class="btn btn-orange text-center align-middle d-none d-sm-none d-md-block">Lo Quiero ►</router-link>
        </div>
      </div>
      <div class="col-12 col-sm-12">
        <div class="row justify-content-center">
          <p class="box-title-h1 mb-0">{{ formatNumber(formulario.dinero) + "€" }}</p>
        </div>
        <div >
          <div id="container_input_range" class="row col-12" >
            <input type="range" min="300" max="6000" step="100" v-model="formulario.dinero" />
          </div>
          <div class="row justify-content-between pl-3 pr-3">
            <div class="col-2 text-center">
              <span class="btn_menos" @click="sumarDinero(-100)"> - </span>
              <span class="euros">300€</span>
            </div>
            <div class="col-8" ></div>
            <div class="col-2 text-center" style="display: flex;flex-direction: column;align-items: flex-end;">
              <span class="btn_mas" @click="sumarDinero(100)"> + </span>
              <span class="euros">6.000€</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block d-sm-block d-md-none mt-4">
        <router-link to="/formulario/step1" class="btn btn-orange btn-mobile text-center align-middle"> Solicitar</router-link>
        
        <div class="review-widget_net mt-3" data-uuid="9c8b113f-e480-46ac-87cd-cf0e14cf65fa" data-template="2" data-lang="es" data-theme="light"></div>
      </div>
    </div>
    <div class="row justify-content-center aling-content-center mb-md-3 d-none d-lg-flex d-xl-flex d-md-flex">
      <div class="col-md-8 col-sm-10 col-xl-7">
        <div class="row justify-content-center">
          <p class="box-title-h1 md_form_res">{{ formatNumber(formulario.dinero) + " €" }}</p>
        </div>
        <div>
          <div id="container_input_range" class="row justify-content-center ">
            <div class="col-md-12 col-lg-11 col-xl-12">
              <div class="text-center ml-3 btn_menos_lg">
                <span class="btn_menos" @click="sumarDinero(-100)"> - </span>
                <span class="euros">300€</span>
              </div>
              <input type="range" min="300" max="6000" step="100" v-model="formulario.dinero" />
              <div class="text-center btn_mas_lg">
                <span class="btn_mas" @click="sumarDinero(100)"> + </span>
                <span class="euros">6.000€</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-sm-12 ">
        <div class="row text-center justify-content-start pt-5">
          <router-link to="/formulario/step1" class="btn btn-orange text-center align-middle d-none d-sm-none d-md-block">Solicitar</router-link>
        </div>
      </div>

      <div class="row d-block d-sm-block d-md-none mt-2">
        <router-link to="/formulario/step1" class="btn btn-orange text-center align-middle"> 
          <div class="d-flex justify-content-center align-items-center">
            <span>Solicitar</span> <img class="pl-3" src="@/assets/img/Vector.svg" alt="Solicitar" >
          </div>
        </router-link>
      </div>
    </div>
  </div>
  <div>
    <p class="d-none d-sm-none d-md-block d-lg-block d-xl-none pt-md-3 pl-md-2 consigue">Consigue en 1 min tu propuesta de préstamo grátis y sin compromiso</p>
  </div>
</template>
<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "NuevoFormulario",
  data() {
    return {};
  },
  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    data_matricula: (state) => state.formulario.data_matricula,
    title: (state) => state.section1.title,
    formulario: (state) => state.formulario,
  }),
  props: {
    keyWord: String,
  },
  methods: {
    sumarDinero: function (suma) {
      let calculo = parseFloat(this.formulario.dinero) + suma;
      if (calculo >= 300 && calculo <= 6000) {
        this.formulario.dinero = calculo;
      }
    },
    formatNumber(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }
  },
  mounted() {
    store.commit("setTitleSection1", "<strong>La mejor solución para un crédito con tu coche</strong>");
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/styles/variables.scss";

.btn-mobile {
  height: 60px;
  font-family: 'Montserrat';
  font-weight: 700;
  letter-spacing: 0.03em;
  width: 246px;
}
#container_input_range {
  position: relative;
  height: 25px;
  margin-top: 0px;

  input[type="range"] {
    width: calc(100% - 55px);
    position: absolute;
    left: 35px;
    display: inline-block;
    -webkit-appearance: none;
    appearance: none;
    margin: 18px 0;

    &:focus {
      outline: none;
    }

    height: 4px;
    cursor: pointer;
    // background: #ffc864;
    background: $orange-2;
    border-radius: 20px;
  }

  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    // background: #ffc864;
    background: $orange-2;
    border-radius: 20px;
  }

  input[type="range"]::-webkit-slider-thumb {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    background: rgb(255, 200, 100);
    background: linear-gradient(180deg, rgba(255, 200, 100, 1) 0%, rgba(255, 165, 0, 1) 100%);
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    position: sticky;
    margin-top: -18px;
  }

  input[type="range"]::-moz-range-thumb {
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: rgb(255, 200, 100);
    background: linear-gradient(180deg, rgba(255, 200, 100, 1) 0%, rgba(255, 165, 0, 1) 100%);
    cursor: pointer;
    -webkit-appearance: none;
    appearance: none;
    margin-top: -10px;
    border: transparent;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: $orange-2;
  }
}

.btn_mas,
.btn_menos {
  display: flex;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  background: $orange-2;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 15px;
}

.btn_menos {
  left: 5px;
}

.btn_menos_lg {
  .btn_menos {
    height: 23px;
    width: 23px;
  }
  font-size: x-large;
  position: absolute;
  top: -10px;
  left: 1px;
  color: $blue;
}
.btn_mas_lg {
  .btn_mas {
    height: 23px;
    width: 23px;
    margin-left: 10px;
  }
  font-size: x-large;
  position: absolute;
  top: -10px;
  right: -1px;
  color: $blue;
}
.centrar-form-lg {
  display: flex;
  align-items: center;
}

.consigue {
  font-size: 1rem !important;
  color: $blue;
  font-weight: 600;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

@include media-breakpoint-between(xs, sm) {

  .box-title-h1 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    color: $blue;
  }
  .btn_menos {
    left: 15px;
  }
  .btn_mas,
  .btn_menos {
    position: absolute;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    background: $orange-2;
    border-radius: 50%;
    cursor: pointer;
    color: white;
    font-size: 20px;

    background-color: $blue;
    margin-top: -15px;
  }
  .euros {
    margin-left: -10px;
    font-family: "Montserrat-Bold" !important;
    color: $blue !important;
  }

  .box-title-h2 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: bolder;
    font-size: 18px;
    margin-top: 20px;
  }

  .btn {
    font-size: 22px !important;
  }
  .euros {
    font-family: "Montserrat";
    font-size: 14px;
    color: #999999;
    line-height: 32px;
  }
  .minuto {
    font-family: 'Montserrat-Bold';
    font-size: 12px;
    line-height: 30px;
    text-align: center;
    color: #004680;
  }
}

@include media-breakpoint-between(sm, md) {
}

@include media-breakpoint-between(md, lg) {
  .btn {
    width: auto;
    font-family: 'Montserrat-Bold';
  }
  .centrar-form-lg {
    align-items: end;
  }
  #container_input_range {
    position: relative;
    height: 25px;
    margin-top: 0px;

    input[type="range"] {
      width: calc(100% - 70px);

      margin: 14px 0;

      &:focus {
        outline: none;
      }

      height: 2px;
      cursor: pointer;
      // background: #ffc864;
      background: $orange-2;
      border-radius: 20px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 1px;
    }
  }
  .card-box {
    background-color: $blue;
    width: 90%;
    height: auto;
    padding: 2vh 3vh 3.2vh 3vh;
    border: 3px solid $blue;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
  }
  .box-title-h1 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    color: $white;
  }
  .box-title-h2 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: $white;
  }
  .btn {
    height: auto;
    font-size: 18px !important;
  }
  .euros {
    font-family: "Montserrat";
    font-size: 14px;
    color: #fff;
    line-height: 32px;
  }
  .md_form_res {
    font-family: "Montserrat-Bold";
    font-size: 3rem;
  }
}

@include media-breakpoint-only(lg) {
  .btn {
    width: auto;
  }
  #container_input_range {
    position: relative;
    height: 25px;
    margin-top: 0px;

    input[type="range"] {
      width: calc(100% - 70px);
      margin: 16px 0;
      &:focus {
        outline: none;
      }
      height: 1px;
      cursor: pointer;
      background: $orange-2;
      border-radius: 20px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 2px;
    }
  }
  .card-box {
    background-color: $blue;
    width: 675px;
    height: auto;
    padding: 1vh 0vh 5vh 1vh;
    border: 3px solid $blue;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    margin-top: 3vh;
  }
  .box-title-h1 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 3.5rem;
    color: $white;
  }

  .box-title-h2 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: $white;
  }
  .euros {
    font-family: "Montserrat";
    font-size: 14px;
    color: #fff;
    line-height: 32px;
  }
}
@include media-breakpoint-only(xl) {
  .btn {
    font-family: 'Montserrat-Bold';
    width: auto;
  }
  #container_input_range {
    position: relative;
    height: 25px;
    margin-top: 0px;

    input[type="range"] {
      width: calc(100% - 70px);
      margin: 16px 0;
      &:focus {
        outline: none;
      }
      height: 1px;
      cursor: pointer;
      background: $orange-2;
      border-radius: 20px;
    }

    input[type="range"]::-webkit-slider-runnable-track {
      height: 2px;
    }
  }
  .card-box {
    background-color: $blue;
    min-width: 605px;
    width: auto;
    height: auto;
    padding: 1vh 0vh 5vh 1vh;
    border: 3px solid $blue;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    margin-top: 3vh;
  }
  .box-title-h1 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 3.5rem;
    color: $white;
  }

  .box-title-h2 {
    font-family: "Montserrat-Bold";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    color: $white;
  }
  .euros {
    font-family: "Montserrat";
    font-size: 14px;
    color: #fff;
    line-height: 32px;
  }
}
</style>
