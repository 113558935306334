<template>
  <div >
    <div>
      <Promo50 v-if="this.afiliado!='04' || this.promo50" />
      <Section1 v-else />
    </div>

    <!--#NF por decision del nuevo diseño se han invertido el orden de las dos secciones consecuentes-->
    <div >
      <Section2 />
    </div>
    <div class='mb-5'>
      <Section3 />
    </div>
    <div class='mb-5'>
      <Section4 />
    </div>
    <div class='mb-5'>
      <Section5 />
    </div>
    <!--div class='mb-5'> REMOVIDO HASTA NUEVO AVISO
      <Section6 />
    </div-->
    <div class='mb-5'>
      <Section8 />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Section1 from '@/components/Home/Section1.vue'
import Promo50 from '@/components/Home/Promo50.vue'
import Section2 from '@/components/Home/Section2.vue'
import Section3 from '@/components/Home/Section3.vue'
import Section4 from '@/components/Home/Section4.vue'
import Section5 from '@/components/Home/Section5.vue'
//import Section6 from '@/components/Home/Section6.vue'
import Section8 from '@/components/Home/Section8.vue'
import { useMeta } from 'vue-meta'
import { computed } from 'vue'
import { useRoute, useRouter } from "vue-router"
import { useStore } from 'vuex'

// @ is an alias to /src
export default {
  name: 'Home',
  components: {
    Section1,
    Promo50,
    Section2,
    Section3,
    Section4,
    Section5,
 //   Section6,
    Section8
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    useMeta({
      title: 'Crédito con tu Coche como Aval',
      meta:[
        {name:'robots', content:store.state.dominio == 'ibancar.com' ? 'index' : 'noindex'},
        {name:'description', content:'Préstamo con aval de coche sin cambio de titularidad. Consigue crédito hasta 6.000€ en 24h. Evita rentings y amortiza desde el primer día. Consigue dinero rápido.'},
        {name:'keywords', content:'Préstamo aval coche, Dinero urgente online, Crédito con coche, Préstamos rápidos y créditos rápidos de dinero online. Empeña tu coche. Refinanciación de coches.'}
      ],
      link:[
        {href: 'https://ibancar.com', rel:'alternate', hreflang:'es-es'},
        {href: 'https://ibancar.mx', rel:'alternate', hreflang:'es-MX'}
      ]
    })

    const carousel1 = ["cision.svg","cordobahoy.svg","elreferente.svg","europapress.svg","libreinversion.svg","yahoo.svg"];
    const carousel1_title = "Lo que dicen de nosotros en prensa";
    const carousel2 = ["mrw.svg","ganvam.svg","bdo.svg","asnef.svg","kineox.svg","transferencia_bancaria.svg","iuvo.svg","oracle.svg","midas.svg"];
    const carousel2_title = "Colaboramos con las mejores marcas del sector";

    return {
      carousel1, carousel1_title, carousel2, carousel2_title, router, route,
      afiliado: computed(() => store.state.afiliado),
      promo50: computed(() => route.name == 'promo50' ? true : false)
    }
  }
  
}
</script>

